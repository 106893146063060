import Seo from 'src/components/seo/Seo'

import Beachwear from '../../components/Campanha/ColecaoBeachwear'

function ColecaoBeachwear2022() {
  return (
    <>
      <Seo title="Campanha | Decathlon" />
      <Beachwear />
    </>
  )
}

export default ColecaoBeachwear2022
